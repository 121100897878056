html, body {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}

.root {

    width: 100%;

    .container {
        width: 100%;
        display: block;
        overflow: auto;
    }

    .modal {
        width: 100vw;
        height: 100vh;
        background: rgba(0,0,0,.3);
        display: none;
        position: absolute;
        top: 0;
        color: #fff;

        &[visible=true] {
            display: block;
            
        }

        &__content {
            padding: 0;
            display: block;
            margin-top: 15vh;
            height: 85vh;
            background: rgba(#000, .6);
            backdrop-filter: blur(10px);
        }
        
    }
}