.question-modal {

    &__window {
        box-sizing: border-box;
        display: block;
        margin-top: 25px;
        width: 100%;
        height: calc(100% - 50px);
        background: #fff;
        color: #000;
        padding: 20px;
    }

    &__title { 
        height: 50px;
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
    }

    &__image {
        height: calc(100% - (200px + 50px));
        img {
            display: block;
            margin: 0 auto;
            max-height: calc(100% - 50px);
        }
        
        h2 {
            padding: 0;
            margin: 0;
            height: 30px;
            line-height: 30px;
            text-align: center;  
        }
    }

    &__opts {
        height: 200px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        button {
            flex: 1 0 45%;
            max-height: 100px;
            display: block;
            float: left;
            padding: 10px;
            margin: 5px;
            cursor: pointer;
            border: solid 1px rgba(0, 0, 0, .1);
            border-radius: 10px;
            background: #fff;
            box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
            font-size: 1.5rem;

            span {
                font-weight: bold;
            }

            &:hover {
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            }
        }

    }

}