bbzl-header {
    background: #efc86c;
    height: 15vh;

    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden !important;

    .header__team-points {
        flex: 1 0;
        font-size: 1.5rem;
        text-align: center;
        color: #555555;
        font-weight: bold;

        &__label {
            user-select: none;
            flex: 0 0;
            width: 100px;
            height: 40px;
            line-height: 40px;
            display: block;
            margin: auto;
            margin-top: 25px;
            margin-bottom: 0;

            &.active { 
                background: #5c67fa;
                color: #fff;
            }
        }

        &__value {
            flex: 0 0;
            width: 100px;
            height: 40px;
            line-height: 40px;
            display: block;
            margin: auto;
        }
    }

    .header__access-code {
        flex: 0 0 150px;
        text-align: center;

        &__label {
            user-select: none;
            flex: 0 0;
            width: 100px;
            height: 30px;
            line-height: 40px;
            display: block;
            margin: auto;
            margin-top: 20px;
            margin-bottom: 0;
            font-weight: bold;
        }

        &__value {
            flex: 0 0;
            width: 100px;
            height: 40px;
            line-height: 40px;
            display: block;
            width: 100%;
            font-size: 1.5rem;
            border: dashed 2px #000;
        }
    }

}