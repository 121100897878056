bbzl-playground {
    
    padding: 10px 20px;

    
    .playground__item {
        box-sizing: unset;
        float: left;
        display: block;
        margin: 5px;
        max-height: 25%;
        
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        user-select: none;
        border-radius: 20px;
        background: #6a9af8;
        color: #fff;

        &.disabled {
            background: none !important;
            color: #ccc;
            pointer-events: none;
            cursor: default;
        }

        &:hover {
            background: #efc86c;
            cursor: pointer;
        }

        &__label {
            flex: 0 0;
            align-self: center;
            font-size: 4rem;
            text-align: center;
            line-height: 40px;
            height: 40px;
        }
    }

}