.rt-modal {

    &__window {
        box-sizing: border-box;
        display: block;
        margin-top: 25px;
        width: 100%;
        height: calc(100% - 50px);
        background: #fff;
        color: #000;
        padding: 20px;
    }

    &__group {

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h2 {
            text-align: center;
            margin: 0;
            padding: 0;
        }

        &:nth-child(1) {
            border-bottom: solid 1px rgba(0,0,0, .1);
        }

        margin: 5px 0;
        height: calc(50% - 10px);

        input, button {
            justify-self: center;
            min-width: 300px;
            height: 30px;
            margin: 5px;
        }

        button {
            background: #41c300;
            border: none;
            color: #fff;
            font-weight: bold;
            cursor: pointer;
            
            &:hover {
                background: rgba(#41c300, .8);
            }
        }

        p {
            margin: 5px 0;
        }
    }

}