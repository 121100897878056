html, body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  overflow-x: hidden;
}

.root {
  width: 100%;
}

.root .container {
  width: 100%;
  display: block;
  overflow: auto;
}

.root .modal {
  color: #fff;
  background: #0000004d;
  width: 100vw;
  height: 100vh;
  display: none;
  position: absolute;
  top: 0;
}

.root .modal[visible="true"] {
  display: block;
}

.root .modal__content {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: #0009;
  height: 85vh;
  margin-top: 15vh;
  padding: 0;
  display: block;
}

bbzl-header {
  background: #efc86c;
  flex-flow: row;
  height: 15vh;
  display: flex !important;
  overflow: hidden !important;
}

bbzl-header .header__team-points {
  text-align: center;
  color: #555;
  flex: 1 0;
  font-size: 1.5rem;
  font-weight: bold;
}

bbzl-header .header__team-points__label {
  -webkit-user-select: none;
  user-select: none;
  flex: 0 0;
  width: 100px;
  height: 40px;
  margin: 25px auto 0;
  line-height: 40px;
  display: block;
}

bbzl-header .header__team-points__label.active {
  color: #fff;
  background: #5c67fa;
}

bbzl-header .header__team-points__value {
  flex: 0 0;
  width: 100px;
  height: 40px;
  margin: auto;
  line-height: 40px;
  display: block;
}

bbzl-header .header__access-code {
  text-align: center;
  flex: 0 0 150px;
}

bbzl-header .header__access-code__label {
  -webkit-user-select: none;
  user-select: none;
  flex: 0 0;
  width: 100px;
  height: 30px;
  margin: 20px auto 0;
  font-weight: bold;
  line-height: 40px;
  display: block;
}

bbzl-header .header__access-code__value {
  border: 2px dashed #000;
  flex: 0 0;
  width: 100%;
  height: 40px;
  font-size: 1.5rem;
  line-height: 40px;
  display: block;
}

bbzl-playground {
  padding: 10px 20px;
}

bbzl-playground .playground__item {
  box-sizing: unset;
  float: left;
  -webkit-user-select: none;
  user-select: none;
  color: #fff;
  background: #6a9af8;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 25%;
  margin: 5px;
  display: flex;
}

bbzl-playground .playground__item.disabled {
  color: #ccc;
  pointer-events: none;
  cursor: default;
  background: none !important;
}

bbzl-playground .playground__item:hover {
  cursor: pointer;
  background: #efc86c;
}

bbzl-playground .playground__item__label {
  text-align: center;
  flex: 0 0;
  align-self: center;
  height: 40px;
  font-size: 4rem;
  line-height: 40px;
}

.question-modal__window {
  box-sizing: border-box;
  color: #000;
  background: #fff;
  width: 100%;
  height: calc(100% - 50px);
  margin-top: 25px;
  padding: 20px;
  display: block;
}

.question-modal__title {
  text-align: center;
  height: 50px;
  font-size: 1.5rem;
  font-weight: bold;
}

.question-modal__image {
  height: calc(100% - 250px);
}

.question-modal__image img {
  max-height: calc(100% - 50px);
  margin: 0 auto;
  display: block;
}

.question-modal__image h2 {
  text-align: center;
  height: 30px;
  margin: 0;
  padding: 0;
  line-height: 30px;
}

.question-modal__opts {
  flex-flow: wrap;
  height: 200px;
  display: flex;
}

.question-modal__opts button {
  float: left;
  cursor: pointer;
  background: #fff;
  border: 1px solid #0000001a;
  border-radius: 10px;
  flex: 1 0 45%;
  max-height: 100px;
  margin: 5px;
  padding: 10px;
  font-size: 1.5rem;
  display: block;
  box-shadow: 0 7px 29px #64646f1a;
}

.question-modal__opts button span {
  font-weight: bold;
}

.question-modal__opts button:hover {
  box-shadow: 0 7px 29px #64646f33;
}

.rt-modal__window {
  box-sizing: border-box;
  color: #000;
  background: #fff;
  width: 100%;
  height: calc(100% - 50px);
  margin-top: 25px;
  padding: 20px;
  display: block;
}

.rt-modal__group {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(50% - 10px);
  margin: 5px 0;
  display: flex;
}

.rt-modal__group h2 {
  text-align: center;
  margin: 0;
  padding: 0;
}

.rt-modal__group:first-child {
  border-bottom: 1px solid #0000001a;
}

.rt-modal__group input, .rt-modal__group button {
  justify-self: center;
  min-width: 300px;
  height: 30px;
  margin: 5px;
}

.rt-modal__group button {
  color: #fff;
  cursor: pointer;
  background: #41c300;
  border: none;
  font-weight: bold;
}

.rt-modal__group button:hover {
  background: #41c300cc;
}

.rt-modal__group p {
  margin: 5px 0;
}

/*# sourceMappingURL=index.5087ade8.css.map */
